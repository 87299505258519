<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'auth'
  }
</script>

<style>

</style>